import { addMonths, format, isAfter, parseISO } from "date-fns";
import { contentBlockSlider, verticalSlider } from "./slider";
import { scrollDown, scrollToTop, watchPageScroll } from "./scroll";

import AOS from "aos";

AOS.init({
  disable: ["phone", "mobile"],
});

const toggleMenuBtn = document.querySelector(".toggle-menu");
const toggleSearchBtn = document.querySelectorAll(".toggle-search");

const menu = document.querySelector(".menu");
const searchBar = document.querySelector(".search-bar");
const body = document.querySelector("body");

toggleMenuBtn.addEventListener("click", () => {
  body.classList.toggle("show-menu");
  body.classList.remove("show-search");
  toggleMenuBtn.classList.toggle("is-active");
});

if (toggleSearchBtn.length > 0) {
  for (let index = 0; index < toggleSearchBtn.length; index++) {
    toggleSearchBtn[index].addEventListener("click", () => {
      body.classList.toggle("show-search");
      body.classList.remove("show-menu");

      if (searchBar.classList.contains("show")) {
        searchBar.classList.toggle("show");
      }

      menu.classList.remove("show");
      toggleMenuBtn.classList.remove("is-active");
    });
  }
}

const openNewsletterBtn = document.querySelector(".open-newsletter");
const closeNewsletterBtn = document.querySelector(".close-newsletter");

openNewsletterBtn.addEventListener("click", () => {
  body.classList.add("show-newsletter");
});

closeNewsletterBtn.addEventListener("click", () => {
  body.classList.remove("show-newsletter");

  const data = {
    nextShowDate: format(addMonths(new Date(), 1), "yyyy-M-d"),
  };

  localStorage.setItem("newsletter", JSON.stringify(data));
});

const initialNewsletterPrompt = () => {
  setTimeout(() => {
    const item = JSON.parse(localStorage.getItem("newsletter"));

    if (!item || isAfter(new Date(), parseISO(item.nextShowDate))) {
      body.classList.add("show-newsletter");
    }
  }, 5000);
};

// initialNewsletterPrompt();

scrollDown();
scrollToTop();
watchPageScroll();

contentBlockSlider();
verticalSlider();
