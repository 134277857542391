const header = document.querySelector("header");
const menu = document.querySelector(".menu");
const scrollPage = document.querySelector(".scroll-page");

/**
 * Round to nearest 5
 * @param {*} num
 * @returns
 */
const roundNearest5 = (num) => {
  return Math.round(num / 5) * 5;
};

const scrollToTop = () => {
  const scrollTop = document.querySelector(".scroll-top");

  if (scrollTop) {
    scrollTop.addEventListener("click", () => window.scrollTo(0, 0));
  }
};

const scrollDown = () => {
  if (scrollPage) {
    scrollPage.addEventListener("click", () => {
      window.scroll({
        top: window.innerHeight / 2,
      });
    });
  }
};

const toggleExploreMore = () => {
  if (scrollPage) {
    setTimeout(() => {
      if (window.scrollY >= 10) {
        scrollPage.classList.add("hide");
      } else {
        scrollPage.classList.remove("hide");
      }
    }, 50);
  }
};

const initHeaderScroll = () => {
  setTimeout(() => {
    if (roundNearest5(window.scrollY - header.clientHeight) >= 0) {
      if (!header.classList.contains("scrolled")) {
        header.classList.add("scrolled");
      }

      // Add scroll top margin to menu
      menu.classList.add("!top-[var(--header-scroll-height)]");
    } else {
      if (header.classList.contains("scrolled")) {
        header.classList.remove("scrolled");
      }
      menu.classList.remove("!top-[var(--header-scroll-height)]");
    }
  }, 50);
};

const toggleMobileActions = () => {
  const mobilePrompt = document.querySelector(".mobile-book-prompts");

  if (window.scrollY >= 100) {
    mobilePrompt.classList.add("show");
  } else {
    mobilePrompt.classList.remove("show");
  }
};

const watchPageScroll = () => {
  document.addEventListener("scroll", () => {
    initHeaderScroll();
    toggleExploreMore();
    toggleMobileActions();
  });
};

export { scrollDown, scrollToTop, watchPageScroll };
