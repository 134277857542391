import Splide from "@splidejs/splide";

const contentBlockSlider = () => {
  const contentElement = document.querySelectorAll(
    ".content-block-slider-content"
  );
  const paginationElement = document.querySelectorAll(
    ".content-block-slider-pagination"
  );

  if (contentElement.length > 0) {
    for (let index = 0; index < contentElement.length; index++) {
      let main = new Splide(contentElement[index], {
        type: "fade",
        rewind: true,
        autoplay: true,
        arrows: false,
        pagination: false,
      });

      let thumbnails = new Splide(paginationElement[index], {
        type: "fade",
        rewind: true,
        arrows: true,
        isNavigation: true,
      });

      main.sync(thumbnails);
      main.mount();
      thumbnails.mount();
    }
  }
};

const verticalSlider = () => {
  const elements = document.querySelectorAll(".vertical-slider");

  if (elements.length > 0) {
    for (let index = 0; index < elements.length; index++) {
      new Splide(elements[index], {
        type: "slide",
        autoplay: true,
        interval: 4000,
        rewind: true,
        pauseOnHover: true,
        direction: "ttb",
        heightRatio: 0.8,
        gap: 24,
        breakpoints: {
          820: {
            direction: "ltr",
            heightRatio: false,
          },
          1180: {
            heightRatio: 1,
          },
        },
      }).mount();
    }
  }
};

export { contentBlockSlider, verticalSlider };
